<template>
  <div class="edu-frame" style="background-color: rgb(239 239 239);">
    <edu-top-header></edu-top-header>
    <div class="edu-group" style="margin: 20px auto;">
      <div class="step-tips">您好，欢迎登录！</div>
      <div class="step-tips-detail">请填写手机号码及密码登录</div>
      <div class="class-form">
        <van-form @submit="login">
          <van-field label-width="70px" required v-model="formData.idCardNo" label="手机号码" placeholder="请输入手机号码" clearable :rules="[{ required: true, message: '请输入手机号码' }]"/>
          <van-field label-width="70px" required v-model="formData.password" type="password" label="密码" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请输入密码' }]"/>
<!--          <div class="form-tips">温馨提示：默认密码为<label style="color: #F57625">123456</label></div>-->
          <van-field label-width="70px" required v-model="formData.verifyCode" center clearable label="验证码" placeholder="请输入验证码" :rules="[{ required: true, message: '请填写验证码' }]">
            <template #button>
              <img @click="getVerifyCode" :src="verifyCode.base64">
            </template>
          </van-field>
          <div class="button-group">
            <van-button size="small" block type="danger" native-type="submit">登录</van-button>
          </div>
          <div class="button-group" style="font-size: 14px;color: dodgerblue;">
            <div style="flex: 1;justify-content: center;display: flex;" @click="$router.push({ name: 'vregisterletter' })">新人注册</div>
            <div style="flex: 1;justify-content: center;display: flex;" @click="$router.push({ name: 'veditpwd' })" >忘记密码</div>
<!--            <van-button size="small" block type="info" native-type="button" @click="$router.go(-1);">新人注册</van-button>
            <van-button size="small" block type="info" native-type="button" @click="editPwd">忘记密码</van-button>-->
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import EduTopHeader from "@/components/VolunteerHeader";
import {Form, Field, Button} from 'vant';
import CustomerApi from "@/api/CustomerApi";
import Tools from "@/api/Tools";
export default {
  components: {
    EduTopHeader,
    VanForm: Form,
    VanField: Field,
    VanButton: Button
  },
  data() {
    return {
      formData: { idCardNo: '', password: '', verifyCode: '', loginType: Tools.getLoginType(), identityType: Tools.getIdentityType() },
      verifyCode: { code: null, base64: null }
    }
  },
  name: 'login',
  methods: {
    login() {
      if (this.formData.verifyCode != this.verifyCode.code) {
        this.$toast('验证码不正确。')
        return
      }
      // TODO 登录
      CustomerApi.loginByVolunteer(this.formData).then(response => {
        if (response.code === 100) {
          Tools.setCurCust(response.res)
          // alert('token == ' + response.res.token)
          Tools.setToken(response.res.token)
          // TODO 判断用户是否有未填写完整新信息
          this.$router.replace({ name: 'vmain' })

        }
      })
    },
    checkHasBlankField() {
      var cust = Tools.getCurCust()
      if (cust.imgUrl == null || cust.imgUrl == '') {
        return 1
      }
      if ((cust.fullName == null || cust.fullName == '') ||
          (cust.urgencyTelNo == null || cust.urgencyTelNo == '') ||
          (cust.urgencyMan == null || cust.urgencyMan == '') ||
          (cust.healthStatus == null || cust.healthStatus == '')) {
        return 1
      }
      if (cust.age >= 18 && (cust.oriDuty == null || cust.oriDuty == '')) {
        return 1
      }
      return 0
    },
    editPwd() {
      this.$router.push({ name: 'editpwd' })
    },
    getVerifyCode() {
      CustomerApi.getVerifyCode().then(response=>{
        if (response.code === 100) {
          this.verifyCode = response.res
        }
      })
    }
  },
  mounted() {
    document.title = '粤美·巾帼故事妈妈线上平台'
    this.getVerifyCode()
  }
}
</script>
<style scoped>
.step-tips-detail {
  font-size: 14px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 15px;

}
.form-tips{
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.button-group {
  margin: 16px;
  display: flex;
}
.van-button {
  margin: 0 5px;
}
</style>
